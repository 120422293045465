<template>
  <div class="main content">
    <h1>Your Legacy Library</h1>
    <div class="container py-4">
      <div class="row">
        <div
          v-for="program in combine8WeeksWithCoaching"
          :key="program.id"
          class="col-12 col-sm-6 col-md-4 program my-4"
          @click="next(program)"
        >
          <h2
            class="text-center"
            style="height: 68px"
          >
            {{ program.title }}
          </h2>
          <div
            v-b-popover.hover.top="!program.purchased ? 'Click To Learn More' : !program.active_enrollment ? 'End of Access, Click to Learn More' : ''"
            class="img-container"
            :class="{ blocked: !program.purchased || !program.active_enrollment }"
          >
            <program-logo
              :blocked="!program.purchased || !program.active_enrollment"
              :title="program.title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramLogo from '@/components/ProgramLogo.vue';

export default {
  name: 'Profile',
  components: {
    ProgramLogo,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    programs() {
      return this.$store.state.programs;
    },
    combine8WeeksWithCoaching() {
      try {
        return this.programs.filter(p => p.title !== '8 Weeks To Be with Group Coaching');
      } catch {
        return [];
      }
    },
  },
  methods: {
    next(program) {
      if (program.active_enrollment) {
        if (program.title === 'Living In Excellence') {
          this.$router.push({ name: 'living_in_excellence' });
        } else {
          this.$router.push({ name: 'program', params: { id: program.active_enrollment.id } });
        }
      } else {
        this.$router.push({ name: 'enroll_new' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.program {
  cursor: pointer;

  .img-container {
    width: 100%;
    border-radius: 50%;
    border: 2px solid transparent;
 }

  .img-container:hover {
    border: 2px solid var(--primary);
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
    transition: border 100ms ease-out, box-shadow 150ms ease-out;
    width: 100%;
    color: blue;
  }

  .blocked:hover {
    border: 2px solid transparent;
    box-shadow: inherit !important;
  }
}
.blocked {
  filter: brightness(90%);
  cursor: help;
}
h2 {
  font-weight: bold;
  margin-bottom: 30px;
}
</style>
